<template>
  <div id="YCF-news-post-box">
    <div class="YCF-news-post-box-row">
      <div class="YCF-news-post-box-leftmenu">
        <hr />
        <router-link to="/News/1">回到列表</router-link>
        <hr class="YCF-hr" />
      </div>
      <section class="YCF-news-post-box-content">
        <hr />
        <header>
          <h1>{{ title }}</h1>
          <time>
            <p>{{ no }}</p>
          </time>
        </header>
        <hr style="border-top: 2px solid #eee" />
        <div class="YCF-news-post-box-content-box">
          <table style="width: 100%">
            <tr>
              <td width="150">No</td>
              <td>{{ no }}</td>
            </tr>
            <tr>
              <td width="150">業主</td>
              <td>{{ owner }}</td>
            </tr>
            <tr>
              <td width="150">工程名稱</td>
              <td>{{ title }}</td>
            </tr>
            <tr>
              <td width="150">工程內容</td>
              <td>{{ content }}</td>
            </tr>
          </table>
          <img :src="image" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ReturnErrorPage } from '@/common/prerender_event'
import { GetMetaData } from '@/common/meta.js'
import qs from 'qs'
export default {
  name: 'newspage',
  data() {
    return {
      title: '',
      no: '',
      content: '',
      imageCover: '',
      owner: '',
      image: '',
      meta_data: null,
    }
  },
  metaInfo() {
    return this.meta_data
  },
  created() {
    this.axios
      .post(
        'https://www.dingchong.com.tw/php/GetNewsList.php',
        qs.stringify({ id: this.$route.params.id })
      )
      .then((response) => {
        if (response.data == '0 results') {
          ReturnErrorPage()
        } else {
          this.owner = response.data.data.owner
          this.title = response.data.data.project_name
          this.no = response.data.data.no
          this.content = response.data.data.project_content
          this.image =
            'https://www.dingchong.com.tw/img/upload/' +
            response.data.data.article_content +
            '.jpg'
          this.meta_data = GetMetaData(
            this.title,
            this.content.replace(/<[^>]*>/g, ''),
            this.image
          )
          this.$nextTick(() => {
            window.prerenderReady = true
          })
        }
      })
  },
}
</script>
